const baseUrl = 'https://op-art.op-ai.fi/v1/art/db/public/artwork/get/';

const assets = [
    { id: 'sky', src: './img/taivas2.jpg', type: 'image' },
    { id: 'pohja', src: './model/pohja-exploded.glb', type: 'gltf' },
    { id: 'navmesh', src: './model/nav-mesh.glb', type: 'gltf' },
    {
        id: 'art_kultainen',
        src: baseUrl + 'ada262a8-9da2-4d8d-8ac0-abb79ef7e5a3',
        type: 'art',
    },
    {
        id: 'art_unten_teilta',
        src: baseUrl + '07b2dceb-5f59-42d7-848d-be870e79c23e',
        type: 'art',
    },
    {
        id: 'art_nimeton',
        src: baseUrl + '98733b86-642f-495c-a1e3-da5ba8559a85',
        type: 'art',
    },
    {
        id: 'art_nimeton2',
        src: baseUrl + '453519ed-f243-471a-b28e-930d93e70527',
        type: 'art',
    },
    {
        id: 'art_vene',
        src: baseUrl + 'af033122-53f5-4f17-89ee-614b869a0fe9',
        type: 'art',
    },
    {
        id: 'art_metso',
        src: baseUrl + 'ca8b9a3c-1356-4403-8be6-50c0aa5b8d36',
        type: 'art',
    },
    {
        id: 'art_talvipaiva',
        src: baseUrl + '31df0e38-feac-4ad9-a7c3-84b2cbbfad73',
        type: 'art',
    },
    {
        id: 'art_vihtavuori',
        src: baseUrl + '185a5329-e327-4a2f-9577-d532dac64c55',
        type: 'art',
    },
    {
        id: 'art_ruovesi',
        src: baseUrl + '6900018a-1079-4c55-ab98-1b7b46446ca0',
        type: 'art',
    },
    {
        id: 'art_sotilas',
        src: baseUrl + '16ee54a4-cf50-4c82-8273-b1aca12c85c2',
        type: 'art',
    },
    {
        id: 'art_metsamaisema',
        src: baseUrl + '5367ed96-5cc7-4984-9d1a-8bc3befb0e0e',
        type: 'art',
    },
    {
        id: 'art_waves',
        src: baseUrl + 'e0ec6846-63f6-4934-97cf-eb9ea79ab7b5',
        type: 'art',
    },
    {
        id: 'art_shelter',
        src: baseUrl + '55faaab2-b0a6-4d66-806d-946973a35ecc',
        type: 'art',
    },
    {
        id: 'art_kuutamo',
        src: baseUrl + 'bd669241-b1b1-4d4b-a31f-7f5bfbc84378',
        type: 'art',
    },
    {
        id: 'art_tilanneV',
        src: baseUrl + 'e3601e84-0f2f-450d-931d-f048949598f5',
        type: 'art',
    },
    {
        id: 'info_tuolit',
        src: baseUrl + '870480ea-faee-4c2b-8cab-d3f8b568ae3c',
        type: 'art',
    },
    // statues
    {
        id: 'nyrkkeilija',
        src: './model/statues/nyrkkeilija2.glb',
        type: 'gltf',
    },
    { id: 'tyontaja', src: './model/statues/tyontaja2.glb', type: 'gltf' },
    { id: 'taklaus', src: './model/statues/taklaus2.glb', type: 'gltf' },
    { id: 'marjatta', src: './model/statues/marjatta2.glb', type: 'gltf' },
    {
        id: 'info_nyrkkeilija',
        src: baseUrl + '6596b839-7d02-429f-92e2-1e7b13ecc763',
        type: 'art',
    },
    {
        id: 'info_tyontaja',
        src: baseUrl + '0443e17e-98b7-4594-9e69-e0abe0ebf289',
        type: 'art',
    },
    {
        id: 'info_taklaus',
        src: baseUrl + 'cf76c0a5-e26c-4401-a854-1b80e19324a5',
        type: 'art',
    },
    {
        id: 'info_marjatta',
        src: baseUrl + '6dab223b-a1bf-472e-a528-a22e1055812b',
        type: 'art',
    },
    // furniture
    { id: 'matto', src: './model/furnitureOpt/carpet.glb', type: 'gltf' },
    {
        id: 'mattoValkoinen',
        src: './model/furnitureOpt/carpet_white.glb',
        type: 'gltf',
    },
    {
        id: 'mattoPunainen',
        src: './model/furnitureOpt/huone_carpet_red.glb',
        type: 'gltf',
    },
    {
        id: 'mattoHuone',
        src: './model/furnitureOpt/huone_carpet1.glb',
        type: 'gltf',
    },
    { id: 'sohvasetti', src: './model/furnitureOpt/sofaset.glb', type: 'gltf' },
    { id: 'nojatuoli', src: './model/furnitureOpt/armchair.glb', type: 'gltf' },
    {
        id: 'poytaPuinen',
        src: './model/furnitureOpt/wooden_table.glb',
        type: 'gltf',
    },
    { id: 'poyta', src: './model/furnitureOpt/poyta.glb', type: 'gltf' },
    {
        id: 'iittala-table',
        src: './model/furnitureOpt/iittala-table.glb',
        type: 'gltf',
    },
    { id: 'lipasto', src: './model/furnitureOpt/lipasto.glb', type: 'gltf' },
    {
        id: 'sivuhuone',
        src: './model/furnitureOpt/sivuhuone.glb',
        type: 'gltf',
    },
    {
        id: 'tuoliHuoneMonta',
        src: './model/furnitureOpt/tuoli-monta.glb',
        type: 'gltf',
    },
];

const $assetsRoot = $('a-assets');
if ($assetsRoot[0].hasLoaded) {
    onAssetsLoaded();
} else {
    $assetsRoot.on('loaded', onAssetsLoaded);
}

function onAssetsLoaded() {
    console.log('primary assets loaded');
    const progressEl = document.getElementById('loading_progress');
    let noAssetsLoaded = 0;
    assets.forEach((a) => {
        $assetsRoot
            .append(`<a-asset-item id="${a.id}" src="${a.src}" />`)
            .children()
            .last()
            .attr('asset-type', a.type)
            .on('loaded', (evt) => {
                let el = evt.target;
                let type = el.getAttribute('asset-type');
                console.log(`${el.id} loaded`);
                if (type === 'gltf') {
                    $(`[delayed-gltf="#${el.id}"]`).attr(
                        'gltf-model',
                        '#' + el.id
                    );
                }
                noAssetsLoaded++;
                let progress = Math.floor(
                    (100 * noAssetsLoaded) / assets.length
                );
                progressEl.setAttribute('text', 'value', progress + ' %');
                if (noAssetsLoaded === assets.length) {
                    console.log(`secondary assets loaded (${noAssetsLoaded})`);
                    openDoors();
                }
            });
    });
}

function openDoors() {
    let h = document.getElementById('loading');
    let ovi_o = h.object3D.getObjectByName('ovi_o');
    let ovi_v = h.object3D.getObjectByName('ovi_v');

    AFRAME.ANIME({
        targets: ovi_o.position,
        duration: 1000,
        delay: 1000,
        z: 0.75,
        x: 0.1,
        begin: (anim) => console.log('opening right door'),
        complete: (anim) => console.log('right door open'),
    });
    AFRAME.ANIME({
        targets: ovi_v.position,
        duration: 1000,
        delay: 1000,
        z: -0.75,
        x: -0.1,
        begin: (anim) => console.log('opening left door'),
        complete: (anim) => console.log('left door open'),
    });
}
